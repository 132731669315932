<template>
  <div id="js-loader" class="loader">
    <div class="loader-animation"></div>
  </div>
  <div v-if="message_type != 'mysdgs'" class="page_header">
    <div class="mv_cloud">
      <div class="right_area">
        <div class="img04">
          <img src="@/assets/front_component/images/assets/back02.png" alt="雲" />
        </div>
      </div>
      <div class="left_area">
        <div class="img01">
          <img src="@/assets/front_component/images/assets/back01.png" alt="雲" />
        </div>
      </div>
    </div>
    <div class="en_ttl en">
      <span>MY {{ this.messagetype.title_en }}</span>
    </div>
    <div class="content_wrap">
      <h2>MY {{ this.messagetype.title_jp }}</h2>
      <div class="mascot_wrap" style="display: none">
        <img src="@/assets/front_component/images/assets/meguru05.png" alt="めぐる君" />
      </div>
      <div class="user_img" :style="{ backgroundImage: 'url(' + profileImg + ')' }" v-if="profileImg"></div>
      <div class="user_img" :style="{
        backgroundImage:
          'url(' +
          require('@/assets/front_component/images/test/test01.png') +
          ')',
      }" v-else></div>
    </div>
  </div>
  <main>
    <div v-if="message_type === 'mysdgs'">
      <SdgsGoalRatio />
    </div>
    <div class="section_all_wrap">
      <div class="content_in_wrap">
        <div id="breadcrumb">
          <ul class="breadcrumb_list">
            <li>
              <div>
                <a style="cursor: pointer" @click="gotoBack()">MY {{ getHistoryUrl() }}一覧</a>
              </div>
            </li>
            <li v-if="
              showmessage.type == 'challenge' || showmessage.type == 'sdgs'
            ">
              <span>{{ showYear(showmessage.option) }}</span>
            </li>
            <li v-else>
              <span>{{ showmessage.title }} </span>
            </li>
          </ul>
        </div>
        <div class="content">
          <div class="innar">
            <div class="post_header">
              <div class="post_header_icon">
                <img src="@/assets/front_component/images/assets/tab01_out.png" alt="すべて表示" />
              </div>
              <div class="post_header_day">
                <span class="post_header_day_time"><img src="@/assets/front_component/images/assets/board01.png"
                    alt="時間" /><time class="en" :datetime="formatDate_time(showmessage.published_at)">{{
                      formatDate(showmessage.published_at) }}
                  </time></span>
                  <template v-if="isMessageType">
                    <div class="post_header_day_name post_header_day_name_d">
                        <span class="post_header_day_name_label">
                          {{ showmessage.type == 'daily' ? '宛先' : '承認者' }}
                        </span>
                        <span>&nbsp;:</span>
                        <span>&nbsp;{{ approved_by }}</span>
                        <span class="post_header_day_name_label">投稿者</span>
                        <span>&nbsp;:</span>
                        <span>&nbsp;{{ posted_by }}</span>
                    </div>
                    </template>

                    <template v-else>
                      <span v-if="showmessage.from_user" class="post_header_day_name">{{ actionUserLabel }}：{{
                      showmessage.deleted_created_by ?  showmessage.from_user +' (削除済み) ': showmessage.from_user }}
                      </span>
                    </template>

                <template v-if="
                  messagecolumn['thanks_type'] && message_type === 'thanks'
                ">
                  <p class="post_header_day_name">
                    タイプ：<span v-for="thanks_type in this.showmessage.thanks_type" :key="thanks_type.id"
                      class="thanks_type">{{ thanks_type.value }}</span>
                  </p>
                </template>
              </div>
            </div>
            <article class="post_area">
              <h1 v-if="message_type == 'thanks'">
                <span v-for="userlist in this.showmessage.target_user" :key="userlist.id">
                  {{ userlist.deleted_at ? userlist.last_name + ' (削除済み) ' : userlist.last_name
                }}さん</span>へのサンクスカード
              </h1>
              <h1 v-else-if="
                showmessage.type == 'challenge' || showmessage.type == 'sdgs'
              ">
                {{ showYear(showmessage.option) }}
              </h1>
              <h1 v-else-if="showmessage.type == 'mysdgs'">{{ showmessage.sdgsReportTitle }}</h1>
              <h1 v-else>{{ showmessage.title }}</h1>

              <div class="submit_wrap">
                <!-- style="display:none" -->
                <Form id="aprroval_modal" class="click_popup dsn2" v-if="
                  messagecolumn['status_list'] == '承認' &&
                  showmessage.from_id == this.$User.id &&
                  showmessage.status != '公開'
                " :style="display" enctype="multipart/form-data" autocomplete="off" @submit="approval_post">
                  <div class="innar">
                    <div class="form_outwrap">
                      <div class="wrap wid_100per">
                        <p class="ttl_03">
                          <!-- top popup -->

                          宛先
                          <ErrorMessage class="each_msg" name="content" />
                        </p>
                        <div class="in_wrap">
                          <div class="select_wrap">
                            <div class="aprroval_message" v-if="message_type == 'news'">
                              内部統括部 執行役員部長に申請が行われます。
                            </div>
                            <div class="aprroval_message" v-else-if="
                              message_type == 'photolog' ||
                              message_type == 'column'
                            ">
                              部門の役職者に申請が行われます。
                            </div>
                            <div v-else>
                              <select v-model="approval_post_data.target_ids" class="">
                                <option v-for="userlist in this.groupUsers.slice()" v-bind:value="userlist.id"
                                  :key="userlist.id">
                                  {{ userlist.last_name
                                  }}{{ userlist.first_name }}
                                  {{
                                    userlist.role
                                    ? " (" + userlist.role + ")"
                                    : ""
                                  }}
                                </option>
                              </select>
                            </div>
                            <div class="t_c">
                              <button type="submit" class="btn btn_submit btn_submit-blue">
                                申請
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Form>
                <div v-if="
                  messagecolumn['status_list'] == '承認' &&
                  showmessage.from_id == this.$User.id &&
                  showmessage.status != '公開'
                " class="tbl_list_command">
                  <div class="commandlist t_c">
                    <div class="submit-btn">
                      <!--  top-edit -->
                      <router-link v-if="
                        showmessage.status != '承認済' &&
                        showmessage.status != '申請中'
                      " class="edit" :to="{
                          name: 'Control messageedit',
                          params: { type: message_type, id: message_id },
                        }">編集
                      </router-link>
                    </div>
                    <!-- <div class="submit-btn">

                      {{ showmessage.status }}
                      <a v-if="
                        showmessage.status != '承認済' &&
                        showmessage.status != '申請中'
                      " class="delete" @click="approvalmodal()" data-tip="'承認申請'">
                        {{
                          approvelText(showmessage.status, showmessage.from_id)
                        }}
                      </a>

                    </div> -->
                  </div>
                </div>
              </div>

              <Form style="display: none" id="aprroval_ng_modal" v-if="showmessage.from_id != this.$User.id"
                enctype="multipart/form-data" autocomplete="off" @submit="approval_ng_message_post">
                <div class="innar">
                  <div class="form_outwrap">
                    <div class="wrap wid_100per">
                      <p class="ttl_03">
                        {{btnStatus === 'approve' ? 'コメント' : '理由'}}
                        {{ btnName }}
                        <ErrorMessage class="each_msg" name="content" />
                      </p>
                      <div class="in_wrap">
                        <Field name="content" type="text" id="approval_input" class="form-control" v-model="approval_ng.content"
                         :rules="btnStatus === 'reject' ? isRequired : ''" />
                      </div>
                    </div>
                    <div class="t_c m_b20">
                      <div class="btn_large">
                        <button type="submit" class="btn btn-primary">
                          {{ btnStatus === 'approve' ? '承認' : '却下' }}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </Form>

              <div v-if="
                messagecolumn['status_list'] == '承認' &&
                showmessage.from_id != this.$User.id  && (showmessage.status !=='却下' && showmessage.status !=='承認済')
              " class="tbl_list_command t_c">
                <div class="submit-btn approval_submit">
                  <!-- <a
                    v-if="showmessage.status == '承認済'"
                    class="edit active"
                    data-tip="承認"
                    >承認</a
                  >
                  <a
                    v-else-if="showmessage.status == '却下'"
                    class="edit not_active"
                    data-tip="承認"
                    >承認</a
                  >
                  <a
                    v-else
                    class="edit"
                    @click="approval_ok_message(showmessage.id)"
                    data-tip="承認"
                    >承認</a
                  > -->

                  <a v-if="showmessage.status != '承認済'" class="edit" @click="approval_ng_message(showmessage.id,'approve')"
                    data-tip="承認">承認</a>
                </div>
                <div class="submit-btn approval_submit">
                  <!-- <a
                    v-if="showmessage.status == '却下'"
                    class="delete active"
                    data-tip="却下"
                    >却下</a
                  >
                  <a
                    v-else-if="showmessage.status == '承認済'"
                    class="delete not_active"
                    data-tip="却下"
                    >却下</a
                  >
                  <a
                    v-else
                    class="delete"
                    @click="approval_ng_message(showmessage.id)"
                    data-tip="却下"
                    >却下
                  </a> -->

                  <a v-if="showmessage.status != '承認済'" class="delete" @click="approval_ng_message(showmessage.id, 'reject')"
                    data-tip="却下">却下
                  </a>
                </div>
              </div>

              <div v-if="
                showmessage.type == 'challenge' || showmessage.type == 'sdgs'
              " style="min-height: 300px">
                <div class="season_wrap">
                  <h3>目標内容 1</h3>
                  <div v-html="showmessage.content"></div>
                </div>
                <div class="season_wrap">
                  <h3>目標内容２</h3>
                  <div v-html="showmessage.content_1"></div>
                </div>
                <div class="season_wrap" v-if="showmessage.content_2 != ''">
                  <h3>目標内容３</h3>
                  <div v-html="showmessage.content_2"></div>
                </div>
              </div>

              <div v-if="
                showmessage.type == 'column' ||
                showmessage.type == 'photolog' ||
                showmessage.type == 'idea' ||
                showmessage.type == 'daily' ||
                showmessage.type == 'thanks' ||
                showmessage.type == 'news' |
                showmessage.type == 'mysdgs'
              " style="min-height: 300px">
                <div class="season_wrap">
                  <!-- <h3>目標内容</h3> -->
                  <div class="word-break" v-html="showmessage.content"></div>
                </div>
              </div>

              <table>
                <tbody>
                  <tr v-if="messagecolumn['sdgs_type']">
                    <th>{{ messagecolumn["sdgs_type"] }}</th>
                    <td>{{ showmessage.sdgs_type }}</td>
                  </tr>
                  <tr v-if="
                    messagecolumn['group'] &&
                    message_type !== 'sdgs' &&                   
                    message_type !== 'column' &&
                    message_type !== 'photolog' &&
                    message_type !== 'idea' &&
                    message_type !== 'challenge'
                  ">
                    <th>{{ messagecolumn["group"] }}</th>
                    <td>
                      {{
                        showmessage.from_user
                        ? showmessage.from_user.group
                          ? showmessage.from_user.group.name
                          : ""
                        : ""
                      }}
                    </td>
                  </tr>

                  <!-- // MFUX-1308 サンクスタイプを投稿者の下に表示する -->
                  <tr v-if="
                    messagecolumn['thanks_type'] && message_type !== 'thanks'
                  ">
                    <th>{{ messagecolumn["thanks_type"] }}</th>
                    <td>
                      <span v-for="thanks_type in this.showmessage.thanks_type" :key="thanks_type.id">{{ thanks_type.value
                      }}</span>
                    </td>
                  </tr>
                  <tr v-if="messagecolumn['public'] && message_type !== 'thanks'">
                    <th>{{ messagecolumn["public"] }}</th>
                    <td>{{ showmessage.public }}</td>
                  </tr>
                  <tr v-if="messagecolumn['published_at']">
                    <th>{{ messagecolumn["published_at"] }}</th>
                    <td>
                      <time class="en" :datetime="format_published(showmessage.published_at)">{{
                        format_published(showmessage.published_at) }}
                      </time>
                    </td>
                  </tr>
                  <tr v-if="
                    messagecolumn['tags'] &&
                    message_type !== 'sdgs' &&
                    message_type !== 'mysdgs' &&
                    message_type !== 'column' &&
                    message_type !== 'photolog' &&
                    message_type !== 'idea' &&
                    message_type !== 'challenge'
                  ">
                    <th>{{ messagecolumn["tags"] }}</th>
                    <td>
                      <span v-for="tag in showmessage.tags" :key="tag.id">{{
                        tag.name
                      }}</span>
                    </td>
                  </tr>
                </tbody>
              </table>

              <div v-if="messagecolumn['photolog']">
                <img id="img_tag_id" />
              </div>

              <!-- <div v-if="files.length > 0">
								<h3>関係ファイルダウンロード</h3>
								<ul class="doc_list">
									<li class="doc_wrap" v-for="file in files" :key="file.id">
										<div class="doc_left">
											<p>{{ file.display_name }}</p>
										</div>
										<div class="doc_right">
											<div class="btn_norm">
												<a v-on:click="dlfile(file.storage_file_name)" class="en" target="_blank" :download="file.updaload_name">DL</a>
											</div>
										</div>
									</li>
								</ul>
							</div> -->

              <div v-if="
                messagecolumn['attached_file'] && showmessage.attached_file
              ">
                <h3>{{ messagecolumn["attached_file"] }}</h3>
                <ul class="doc_list">
                  <li class="doc_wrap" v-for="file in showmessage.attached_file" :key="file.id">
                    <div class="doc_left">
                      <p>{{ file.display_name }}</p>
                    </div>
                    <div class="doc_right">
                      <div class="btn_norm">
                        <a v-on:click="dlfile(file.storage_file_name)" class="en" target="_blank"
                          :download="file.updaload_name">DL</a>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </article>
            <div v-if="message_type == 'daily'">
              <div class="reply_area" v-if="reply_messages.data != 0">
                <div class="reply_wrap" v-for="(message, index) in reply_messages" :key="message.id"
                  :id="'reply' + (index + 1)">
                  <p class="res_head">
                    <span class="res_num en">{{ index + 1 }}</span><span class="en">.</span>
                    <span class="res_name" v-if="message.from_user">{{ message.from_user.last_name }}
                      {{ message.from_user.first_name }}</span>
                    <span class="res_day_time">
                      <img src="@/assets/front_component/images/assets/board01.png" alt="時間" />
                      <time class="en" :datetime="formatDate_time(message.published_at)">{{
                        formatDate(message.published_at) }}</time>
                    </span>
                  </p>
                  <p v-if="message.reply_com_id" class="p_t10">
                    <a :href="'#reply' + message.reply_com_id">>> {{ message.reply_com_id }}</a>
                  </p>
                  <div class="post_area">
                    <p v-html="htmlText(message.content)"></p>
                  </div>
                </div>
              </div>
            </div>
            <div class="submit_wrap">
              <!-- style="display:none" -->
              <Form id="aprroval_modal_under" class="click_popup dsn2" :style="display" v-if="
                messagecolumn['status_list'] == '承認' &&
                showmessage.from_id == this.$User.id &&
                showmessage.status != '公開'
              " enctype="multipart/form-data" autocomplete="off" @submit="approval_post">
                <div class="innar">
                  <div class="form_outwrap">
                    <div class="wrap wid_100per">
                      <!-- below popup -->
                      <p class="ttl_03">
                        宛先
                        <ErrorMessage class="each_msg" name="content" />
                      </p>
                      <div class="in_wrap">
                        <div class="select_wrap">
                          <div class="aprroval_message" v-if="message_type == 'news'">
                            内部統括部 執行役員部長に申請が行われます。
                          </div>
                          <div class="aprroval_message" v-else-if="
                            message_type == 'photolog' ||
                            message_type == 'column'
                          ">
                            部門の役職者に申請が行われます。
                          </div>
                          <div v-else>
                            <select v-model="approval_post_data.target_ids" class="">
                              <option v-for="userlist in this.groupUsers.slice()" v-bind:value="userlist.id"
                                :key="userlist.id">
                                {{ userlist.last_name
                                }}{{ userlist.first_name }}
                                {{
                                  userlist.role
                                  ? " (" + userlist.role + ")"
                                  : ""
                                }}
                              </option>
                            </select>
                          </div>
                          <div class="t_c">
                            <button type="submit" class="btn btn_submit btn_submit-blue">
                              申請
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Form>
              <div v-if="
                messagecolumn['status_list'] == '承認' &&
                showmessage.from_id == this.$User.id &&
                showmessage.status != '公開'
              " class="tbl_list_command">
                <div class="commandlist t_c">
                  <div class="submit-btn">
                    <!-- buttom edit -->
                    <router-link v-if="
                      showmessage.status != '承認済' &&
                      showmessage.status != '申請中'
                    " class="edit" :to="{
                      name: 'Control messageedit',
                      params: { type: message_type, id: message_id },
                    }">編集</router-link>
                  </div>
                  <!-- <div class="submit-btn">

                    <a v-if="
                      showmessage.status != '承認済' &&
                      showmessage.status != '申請中'
                    " class="delete" @click="approvalmodal_under()" data-tip="承認申請">
                      {{
                        approvelText(showmessage.status, showmessage.from_id)
                      }}</a>

                  </div> -->
                </div>
              </div>
            </div>

            <Form style="display: none" id="aprroval_ng_modal" v-if="showmessage.from_id != this.$User.id"
              enctype="multipart/form-data" autocomplete="off" @submit="approval_ng_message_post">
              <div class="innar">
                <div class="form_outwrap">
                  <div class="wrap wid_100per">
                    <p class="ttl_03">
                      {{btnStatus === 'approve' ? 'コメント' : '理由'}}
                       {{ btnName }}
                      <ErrorMessage class="each_msg" name="content" />
                    </p>
                    <div class="in_wrap">
                      <Field name="content" type="text" class="form-control" v-model="approval_ng.content"
                        :rules="btnStatus === 'reject' ? isRequired : ''" />
                    </div>
                  </div>
                  <div class="t_c">
                    <div class="btn_large">
                      <button type="submit" class="btn btn-primary">
                        {{ btnStatus === 'approve' ? '承認' : '却下' }}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </Form>
            <div v-if="showmessage.type == 'thanks'">
              <div class="good_wrap">
                <div class="good_button" v-if="showmessage.good_self !== true" @click.once="good_post(showmessage.id)">
                  <div class="icon_wrap">
                      <img
                        src="@/assets/front_component/images/assets/good01.png"
                        alt="いいね"
                        class="mouse_out"
                      />
                      <img
                        src="@/assets/front_component/images/assets/good02.png"
                        alt="いいね"
                        class="mouse_over"
                      />
                  </div>
                  <span class="en good_num" style="padding-bottom: 25px; color: #e6563b; margin-left: -5px;!important" v-if="showmessage.good_num > 0">+{{ showmessage.good_num }}</span>
                </div>
                <div class="good_button active" v-else @click.once="good_delete(showmessage.id)">
                    <div class="icon_wrap_active">
                      <img
                      src="@/assets/front_component/images/assets/good01.png"
                      alt="いいね"
                      class="mouse_over_color"
                      />
                    </div>
                  <span class="en good_num" style="padding-bottom: 25px; color: #e6563b margin-left: -5px;!important" v-if="showmessage.good_num > 0">+{{ showmessage.good_num }}</span>                  
                </div>
                <div class="good_button"  v-if="showmessage.good_like_self !== true" >
                  <div class="icon-like-wrap" @click.once="good_like_post(showmessage.id)">
                    <svg class="bi bi-hand-thumbs-up icon-like" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg"><path class="icon-like-path" d="M8.864.046C7.908-.193 7.02.53 6.956 1.466c-.072 1.051-.23 2.016-.428 2.59-.125.36-.479 1.013-1.04 1.639-.557.623-1.282 1.178-2.131 1.41C2.685 7.288 2 7.87 2 8.72v4.001c0 .845.682 1.464 1.448 1.545 1.07.114 1.564.415 2.068.723l.048.03c.272.165.578.348.97.484.397.136.861.217 1.466.217h3.5c.937 0 1.599-.477 1.934-1.064a1.86 1.86 0 0 0 .254-.912c0-.152-.023-.312-.077-.464.201-.263.38-.578.488-.901.11-.33.172-.762.004-1.149.069-.13.12-.269.159-.403.077-.27.113-.568.113-.857 0-.288-.036-.585-.113-.856a2.144 2.144 0 0 0-.138-.362 1.9 1.9 0 0 0 .234-1.734c-.206-.592-.682-1.1-1.2-1.272-.847-.282-1.803-.276-2.516-.211a9.84 9.84 0 0 0-.443.05 9.365 9.365 0 0 0-.062-4.509A1.38 1.38 0 0 0 9.125.111L8.864.046zM11.5 14.721H8c-.51 0-.863-.069-1.14-.164-.281-.097-.506-.228-.776-.393l-.04-.024c-.555-.339-1.198-.731-2.49-.868-.333-.036-.554-.29-.554-.55V8.72c0-.254.226-.543.62-.65 1.095-.3 1.977-.996 2.614-1.708.635-.71 1.064-1.475 1.238-1.978.243-.7.407-1.768.482-2.85.025-.362.36-.594.667-.518l.262.066c.16.04.258.143.288.255a8.34 8.34 0 0 1-.145 4.725.5.5 0 0 0 .595.644l.003-.001.014-.003.058-.014a8.908 8.908 0 0 1 1.036-.157c.663-.06 1.457-.054 2.11.164.175.058.45.3.57.65.107.308.087.67-.266 1.022l-.353.353.353.354c.043.043.105.141.154.315.048.167.075.37.075.581 0 .212-.027.414-.075.582-.05.174-.111.272-.154.315l-.353.353.353.354c.047.047.109.177.005.488a2.224 2.224 0 0 1-.505.805l-.353.353.353.354c.006.005.041.05.041.17a.866.866 0 0 1-.121.416c-.165.288-.503.56-1.066.56z"/></svg>
                  </div>
                  <span class="en good_num" style="padding-bottom: 25px; color: rgb(66, 129, 201); margin-left: -1px;!important" v-if="showmessage.good_like_num > 0">+{{ showmessage.good_like_num }}</span>
                </div>
                <div class="good_button" v-else>
                  <div class="icon_wrap" style="background-color: #3197e0; border: #3197e0 solid 1px;" @click.once="good_like_delete(showmessage.id)">
                    <svg class="bi bi-hand-thumbs-up icon-like" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg"><path class="icon-like-path" style="fill: #ffffff" d="M8.864.046C7.908-.193 7.02.53 6.956 1.466c-.072 1.051-.23 2.016-.428 2.59-.125.36-.479 1.013-1.04 1.639-.557.623-1.282 1.178-2.131 1.41C2.685 7.288 2 7.87 2 8.72v4.001c0 .845.682 1.464 1.448 1.545 1.07.114 1.564.415 2.068.723l.048.03c.272.165.578.348.97.484.397.136.861.217 1.466.217h3.5c.937 0 1.599-.477 1.934-1.064a1.86 1.86 0 0 0 .254-.912c0-.152-.023-.312-.077-.464.201-.263.38-.578.488-.901.11-.33.172-.762.004-1.149.069-.13.12-.269.159-.403.077-.27.113-.568.113-.857 0-.288-.036-.585-.113-.856a2.144 2.144 0 0 0-.138-.362 1.9 1.9 0 0 0 .234-1.734c-.206-.592-.682-1.1-1.2-1.272-.847-.282-1.803-.276-2.516-.211a9.84 9.84 0 0 0-.443.05 9.365 9.365 0 0 0-.062-4.509A1.38 1.38 0 0 0 9.125.111L8.864.046zM11.5 14.721H8c-.51 0-.863-.069-1.14-.164-.281-.097-.506-.228-.776-.393l-.04-.024c-.555-.339-1.198-.731-2.49-.868-.333-.036-.554-.29-.554-.55V8.72c0-.254.226-.543.62-.65 1.095-.3 1.977-.996 2.614-1.708.635-.71 1.064-1.475 1.238-1.978.243-.7.407-1.768.482-2.85.025-.362.36-.594.667-.518l.262.066c.16.04.258.143.288.255a8.34 8.34 0 0 1-.145 4.725.5.5 0 0 0 .595.644l.003-.001.014-.003.058-.014a8.908 8.908 0 0 1 1.036-.157c.663-.06 1.457-.054 2.11.164.175.058.45.3.57.65.107.308.087.67-.266 1.022l-.353.353.353.354c.043.043.105.141.154.315.048.167.075.37.075.581 0 .212-.027.414-.075.582-.05.174-.111.272-.154.315l-.353.353.353.354c.047.047.109.177.005.488a2.224 2.224 0 0 1-.505.805l-.353.353.353.354c.006.005.041.05.041.17a.866.866 0 0 1-.121.416c-.165.288-.503.56-1.066.56z"/></svg>
                  </div>
                  <span class="en good_num" style="padding-bottom: 25px; color: rgb(66, 129, 201); margin-left: -6px;!important" v-if="showmessage.good_like_num > 0">+{{ showmessage.good_like_num }}</span>
                </div>
              </div>
            </div>
            <div v-if="
              messagecolumn['status_list'] == '承認' &&
              showmessage.from_id != this.$User.id  && (showmessage.status !=='却下' && showmessage.status !=='承認済')
            " class="tbl_list_command t_c">
              <div class="submit-btn approval_submit">
                <!-- <a
                  v-if="showmessage.status == '承認済'"
                  class="edit active"
                  data-tip="承認"
                  >承認</a
                >
                <a
                  v-else-if="showmessage.status == '却下'"
                  class="edit not_active"
                  data-tip="承認"
                  >承認</a
                >
                <a
                  v-else
                  class="edit"
                  @click="approval_ok_message(showmessage.id)"
                  data-tip="承認"
                  >承認</a
                > -->
                <a v-if="showmessage.status != '承認済'" class="edit" @click="approval_ng_message(showmessage.id, 'approve')"
                  data-tip="承認">承認</a>
              </div>
              <div class="submit-btn approval_submit">
                <!-- <a
                  v-if="showmessage.status == '却下'"
                  class="delete active"
                  data-tip="却下"
                  >却下</a
                >
                <a
                  v-else-if="showmessage.status == '承認済'"
                  class="delete not_active"
                  data-tip="却下"
                  >却下</a
                >
                <a
                  v-else
                  class="delete"
                  @click="approval_ng_message(showmessage.id)"
                  data-tip="却下"
                  >却下</a
                > -->

                <a v-if="showmessage.status != '承認済'" class="delete" @click="approval_ng_message(showmessage.id, 'reject')"
                  data-tip="却下">却下</a>
              </div>
            </div>
            <div class="com_area" id="reply_textarea" v-if="showmessage.status == '却下' || showmessage.status == '承認済'">
                <div class="com_wrap">
                  <p class="com_name" v-if="showmessage.status == '却下'">
                    コメント（却下）
                  </p>
                  <p class="com_name" v-else>
                    コメント（承認）
                  </p>
                  <div class="comment">
                    <textarea
                      rows="5"
                      readonly
                      v-model="this.approval_message"
                    >
                    </textarea>
                  </div>
                </div>
              </div>
            <div class="t_c m_t100 m_b50">
              <div class="btn_wide blue">
                <router-link :to="{ name: 'Control message', params: { type: message_type } }">一覧にもどる</router-link>
              </div>
            </div>
          </div>
          <div class="deco_wrap01">
            <img src="@/assets/front_component/images/assets/bard01.png" alt="青鳥" />
          </div>
          <div class="deco_wrap02">
            <img src="@/assets/front_component/images/assets/bard02.png" alt="緑鳥" />
          </div>
        </div>
      </div>
      <div class="kusa_outline">
        <div class="kusa_area">
          <div class="deco_wrap01">
            <img src="@/assets/front_component/images/assets/kusa01.png" alt="草" />
          </div>
          <div class="deco_wrap02">
            <img src="@/assets/front_component/images/assets/kusa02.png" alt="草" />
          </div>
          <div class="deco_wrap03">
            <img src="@/assets/front_component/images/assets/kusa04.png" alt="草" />
          </div>
          <div class="deco_wrap04">
            <img src="@/assets/front_component/images/assets/kusa03.png" alt="草" />
          </div>
          <div class="deco_wrap05">
            <img src="@/assets/front_component/images/assets/kusa04.png" alt="草" />
          </div>
          <div class="deco_wrap06">
            <img src="@/assets/front_component/images/assets/kusa03.png" alt="草" />
          </div>
          <div class="deco_wrap07">
            <img src="@/assets/front_component/images/assets/kusa05.png" alt="草" />
          </div>
          <div class="deco_wrap08">
            <img src="@/assets/front_component/images/assets/kusa04.png" alt="草" />
          </div>
          <div class="deco_wrap09">
            <img src="@/assets/front_component/images/assets/kusa01.png" alt="草" />
          </div>
          <div class="deco_wrap10">
            <img src="@/assets/front_component/images/assets/kusa07.png" alt="草" />
          </div>
          <div class="deco_wrap11">
            <img src="@/assets/front_component/images/assets/kusa06.png" alt="草" />
          </div>
          <div class="deco_wrap12">
            <img src="@/assets/front_component/images/assets/kusa01.png" alt="草" />
          </div>
          <div class="deco_wrap13">
            <img src="@/assets/front_component/images/assets/kusa04.png" alt="草" />
          </div>
          <div class="deco_wrap14">
            <img src="@/assets/front_component/images/assets/kusa03.png" alt="草" />
          </div>
          <div class="deco_wrap15">
            <img src="@/assets/front_component/images/assets/kusa04.png" alt="草" />
          </div>
          <div class="deco_wrap16">
            <img src="@/assets/front_component/images/assets/kusa03.png" alt="草" />
          </div>
          <div class="deco_wrap17">
            <img src="@/assets/front_component/images/assets/kusa01.png" alt="草" />
          </div>
        </div>
      </div>
      <div class="deco_outline">
        <div class="deco_area">
          <div class="deco_wrap01">
            <img src="@/assets/front_component/images/assets/kikyu01.png" alt="気球" />
          </div>
          <div class="deco_wrap02">
            <img src="@/assets/front_component/images/assets/hana01.png" alt="花" class="hana01" />
            <img src="@/assets/front_component/images/assets/hana02.png" alt="花" class="hana02" />
            <img src="@/assets/front_component/images/assets/hana03.png" alt="花" class="hana03" />
          </div>
          <div class="deco_wrap03">
            <img src="@/assets/front_component/images/assets/hana01.png" alt="花" class="hana01" />
            <img src="@/assets/front_component/images/assets/hana02.png" alt="花" class="hana02" />
            <img src="@/assets/front_component/images/assets/hana03.png" alt="花" class="hana03" />
          </div>
          <div class="deco_wrap04">
            <img src="@/assets/front_component/images/assets/heri01.png" alt="ヘリコプター" />
          </div>
          <div class="deco_wrap05">
            <img src="@/assets/front_component/images/assets/kikyu02.png" alt="気球" />
          </div>
          <div class="deco_wrap06">
            <img src="@/assets/front_component/images/assets/hana01.png" alt="花" class="hana01" />
            <img src="@/assets/front_component/images/assets/hana02.png" alt="花" class="hana02" />
            <img src="@/assets/front_component/images/assets/hana03.png" alt="花" class="hana03" />
          </div>
          <div class="deco_wrap07">
            <img src="@/assets/front_component/images/assets/hana01.png" alt="花" class="hana01" />
            <img src="@/assets/front_component/images/assets/hana02.png" alt="花" class="hana02" />
            <img src="@/assets/front_component/images/assets/hana03.png" alt="花" class="hana03" />
          </div>
          <div class="deco_wrap08">
            <img src="@/assets/front_component/images/assets/kikyu03.png" alt="気球" />
          </div>
        </div>
      </div>
    </div>
  </main>
  <div class="menu_wrap">
    <div class="menu_icon">
      <img src="@/assets/front_component/images/assets/menu01.png" alt="メニュー" />
    </div>
    <div class="menu_list_wrap">
      <div class="menu_ttl">作成する</div>
      <ul class="menu_list">
        <li>
          <router-link
            :to="{
              name: 'Control messagecreate',
              params: { type: 'mysdgs' },
            }"
            >SDGsレポート</router-link
          >
        </li>
        <!-- <li>
          <router-link
            :to="{ name: 'Control messagecreate', params: { type: 'news' } }"
            >お知らせ</router-link
          >
        </li> -->
        <li>
          <router-link :to="{ name: 'Control messagecreate', params: { type: 'thanks' } }">サンクス&ホメロンカード</router-link>
        </li>
        <li>
          <router-link :to="{ name: 'Control messagecreate', params: { type: 'idea' } }">創発カード</router-link>
        </li>
        <li>
          <router-link :to="{ name: 'Control messagecreate', params: { type: 'daily' } }">気づき日報</router-link>
        </li>
        <li>
          <router-link :to="{
            name: 'Control messagecreate',
            params: { type: 'photolog' },
          }">フォトログ</router-link>
        </li>
        <li>
          <router-link :to="{ name: 'Control messagecreate', params: { type: 'column' } }">コラム</router-link>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import Api from "@/apis/Api";
import "@/assets/front_component/js/functions.js";
import $ from "jquery";
import message from "@/apis/Message";
import user from "@/apis/User";
import moment from "moment";

//import $ from "jquery";
import MessageColumn from "@/const/MessageColumn.json";
import MessageType from "@/const/MessageType.json";
import NewsUtility from "@/apis/News";
import dayjs from "dayjs";
import { Form, Field, ErrorMessage } from "vee-validate";
import Groups from "../../../../apis/Groups";
import striptags from "striptags";
import SdgsGoalRatio from '../sdgs/SdgsGoalRatio';

export default {
  name: "message",
  data() {
    return {
      btnStatus: "",
      approved_by: null,
      posted_by: null,
      userlists: "",
      target_user_id: "",
      showmessage: {
        content_1: "",
        content_2: "",
        option: "",
      },
      good_message: {
        title: "掲示板のいいね",
        content: "掲示板のいいね",
        target_ids: [],
        from_type: "users",
        from_id: this.$User.id,
        type: "board_good",
        content_type: "HTML",
        public: "非公開",
        status: "公開",
        parent_id: "",
      },
      good_like_message: {
        title: "掲示板のいいね",
        content: "掲示板のいいね",
        target_ids: [],
        from_type: "users",
        from_id: this.$User.id,
        type: "thumps_like",
        content_type: "HTML",
        public: "非公開",
        status: "公開",
        parent_id: "",
      },
      editmessage: {},
      approval_message: null,
      MessageColumn() {
        return MessageColumn;
      },
      messagecolumn: {},
      MessageType() {
        return MessageType;
      },
      messagetype: {},
      files: {},
      approval_ng: {},
      approval_post_data: {},
      reply_messages: {},
      profileImg: false,
      groupUsers: [],
      actionUserLabel: "投稿者",
      prevRoute: null,
      approverName: null
    };
  },
  beforeRouteEnter(to, from, next) {
    console.log(from);
    next((vm) => {
      vm.prevRoute = from;
    });
  },
  components: {
    Form,
    Field,
    ErrorMessage,
    SdgsGoalRatio
  },
  computed: {
    display() {
      if (
        (this.message_type == "photolog" || this.message_type == "column") &&
        this.showmessage.status == "下書き"
      ) {
        return "display:block";
      } else {
        return "display:none";
      }
    },
    isMessageType() {
      if (this.showmessage.type == 'column' || this.showmessage.type == 'idea' || this.showmessage.type == 'photolog' || this.showmessage.type == 'daily' || this.showmessage.type == 'sdgs' || this.showmessage.type == 'challenge') {
        return true;
      } else {
        return false;
      }
    }
  },

  watch: {
    showmessage(val) {
      console.log(val);
    },
  },
  beforeCreate() {
    $(function () {
      // body に open クラスをつけたりはずしたりする( open クラスは空)
      $(".g_header_list").removeClass("open");
      $("#button").removeClass("active");
      $(document.body).removeClass("open");
      var result = $("body").attr("style");
      var int_data = result.replace(/[^0-9]/g, "");
      $("html,body").css({
        overflow: "auto",
        height: "auto",
      });
      //bodyfixedを解除する
      $("body").css({
        position: "",
        width: "",
        top: "",
      });
      $(window).scrollTop(int_data);
      $(".g_header_list  > li.has_child").removeClass("open");
      $(".g_header_list  > li.has_child").find(".menu_outwrap").slideUp();
    });
    $(function () {
      $("#js-loader").show();
      $(window).scrollTop(0);
    });
  },
  created() {
    this.message_type = this.$route.params.type;
    this.message_id = this.$route.params.id;
    this.messagecolumn = MessageColumn[this.message_type];
    this.messagetype = MessageType[this.message_type];
    this.getUserLists();
    this.ShowMessage(this.message_id);
    this.getFile(this.message_id);
    if (this.message_type == "daily") {
      this.replyList(this.message_id);
    }
    this.profileImgApi();

    if (this.$User.group) {

      //親のグループIDを取得
      var target_group_id_oya_daily = this.$User.group ? this.$User.group.id : null;

      if (
        this.$User.group.parent_id &&
        (this.message_type == "idea" ||
          this.message_type == "sdgs" ||
          this.message_type == "challenge")
      ) {
        target_group_id_oya_daily = this.$User.group.parent_id;
      } else {
        target_group_id_oya_daily = this.$User.group.id;
      }

      Groups.getGroupUsersByGroupId(
        target_group_id_oya_daily,
        this.message_type
      ).then((response) => {
        this.groupUsers = response.data.data;
      });
    }

  },
  mounted() {
    document.body.className = "page_control page_template page_sdgs";
    this.$nextTick(function () {
      $(function () {
        $(".menu_icon").click(function () {
          if ($(".menu_list_wrap").hasClass("show")) {
            $(".menu_list_wrap").removeClass("show");
          } else {
            $(".menu_list_wrap").addClass("show");
          }
        });
      });
      $(document).click(function (event) {
        if (!$(event.target).closest(".menu_wrap").length) {
          $(".menu_list_wrap").removeClass("show");
        }
      });
      $(function () {
        var content_height = $("#app").height(); // コンテンツの高さを取得
        var pagetop_show = content_height - 1500; // ページトップを出すの高さを設定
        $(window).on("scroll", function () {
          var scroll = $(window).scrollTop();
          var windowHeight = $(window).height();
          //ページトップ表示
          if (scroll > pagetop_show && scroll > 60) {
            $(".pagetop_wrap").addClass("show");
          } else {
            $(".pagetop_wrap").removeClass("show");
          }
        });
      });
      $(function () {
        $("#js-loader").delay(300).fadeOut(600);
        $(window).scrollTop(0);
      });
      // $(window).on('load',function(){
      // 	$(".media").addClass("selected");
      // 	alert('アラーートだよ');
      // });
    });
  },
  methods: {
    profileImgApi() {
      Api()
        .get("api/v1/files/users/" + this.$User.id + "?where=key@users")
        .then(
          (response) => {
            if (response != "") {
              let path = response.data.data[0].storage_file_name;
              let fileApiPass = "/api/v1/file/" + path;
              Api()
                .get(fileApiPass, { responseType: "arraybuffer" })
                .then(
                  (response) => {
                    let blob = new Blob([response.data], {
                      type: response.headers["content-type"],
                    });
                    let url = window.URL || window.webkitURL;
                    let src = url.createObjectURL(blob);
                    this.profileImg = src;
                  },
                  (error) => { }
                );
            }
          },
          (error) => {
            console.log(error);
          }
        )
        .catch(() => { });
    },
    gotoBack() {
      if (window.history.state.back == "/approval") {
        this.$router.push({
          name: "Front approval",
          query: {
            row_count: this.$route.query.row_count
              ? this.$route.query.row_count
              : 25,
          },
        });
      } else {
        this.$router.push({
          name: "Control message",
          params: { type: this.message_type },
          query: {
            row_count: this.$route.query.row_count
              ? this.$route.query.row_count
              : 25,
          },
        });
      }
    },
    getHistoryUrl() {
      if (window.history.state.back == "/approval") {
        return "承認";
      } else {
        return this.messagetype.title_jp;
      }
    },
    getUserLists() {
      if (this.messagecolumn["status_list"] == "投稿") {
        user
          .list()
          .then((response) => {
            if (response != null) {
              this.userlists = response.data.data;
            }
          })
          .catch((error) => {
            console.log(error);
            this.$router.push({ name: "Front Error Catch" });
          })
          .finally(() => {
            this.loader = false;
          });
      } else if (this.messagecolumn["status_list"] == "承認") {
        var target_user_data = [];

        if (this.$User.group) {
          user
            .get_group(this.$User.group.id)
            .then((response) => {
              if (response != null) {
                if (response.data.data.group_users.length > 0) {
                  response.data.data.group_users.forEach((user_data) => {
                    if (user_data.role != null) {
                      user
                        .show(user_data.user_id)
                        .then((response) => {
                          if (response != null) {
                            target_user_data.push(response.data.data);
                          }
                        })
                        .catch((error) => {
                          console.log(error);
                        })
                        .finally(() => {
                          this.loader = false;
                        });
                    }
                  });
                  this.userlists = target_user_data;
                }
              }
            })
            .catch((error) => {
              console.log(error);
              // this.$router.push({ name: 'Front Error Catch' });
            })
            .finally(() => {
              this.loader = false;
            });
        }
      }
    },
    isRequired(value) {
      if (value) {
        return true;
      }
      return "必須です";
    },
    formatDate: (dateStr) => dayjs(dateStr).format("YYYY.MM.DD"),
    formatDate_time: (dateStr) => dayjs(dateStr).format("YYYY.MM.DD"),
    formattime: (dateStr) =>
      dayjs(dateStr).add(9, "h").format("YYYY-MM-DD hh:mm:ss"),
    format_published: (dateStr) => dayjs(dateStr).format("YYYY.MM.DD"),
    format_date(value) {
      if (value) {
        return moment(String(value)).format("YYYY-MM-DD hh:mm:ss");
      }
    },
    approvalmodal() {
      $("#aprroval_modal").show();
      this.approval_post_data.target_ids = this.showmessage.target_ids[0];
    },
    approvalmodal_under() {
      $("#aprroval_modal_under").show();
      this.approval_post_data.target_ids = this.showmessage.target_ids[0];
    },
    approval_post() {
      this.showmessage.status = "申請中";

      this.showmessage.published_at = moment(
        this.showmessage.published_at
      ).format("YYYY-MM-DD HH:mm:ss");

      if (
        this.message_type != "news" &&
        this.message_type != "photolog" &&
        this.message_type != "column"
      ) {
        this.showmessage.target_ids[0] = this.approval_post_data.target_ids;
      }

      var post_tag = [];

      if (this.showmessage.tags) {
        this.showmessage.tags.forEach((tag) => {
          post_tag.push(tag.id);
        });
        this.showmessage.tags = post_tag;
      }

      if (this.message_type == "sdgs" || this.message_type == "challenge") {
        this.showmessage.content = JSON.stringify({
          content: this.showmessage.content,
          content_1: this.showmessage.content_1,
          content_2: this.showmessage.content_2,
          option: this.showmessage.option,
          month: this.showmessage.month,
        });
      }

      this.showmessage.target_type = "users";

      this.showmessage.target_ids = this.showmessage.target_ids.filter(
        (item) => {
          return item != this.$User.id;
        }
      );

      message
        .update(this.showmessage, this.message_id)
        .then((response) => {
          if (response != null) {
            this.$router.push({
              name: "Control message",
              params: { type: this.message_type },
            });
          }
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.loader = false;
        });
    },
    approval_ok_message(messageid) {
      this.showmessage.status = "承認済";
      this.showmessage.published_at = this.formattime(
        this.showmessage.published_at
      );
      var post_tag = [];

      if (this.showmessage.tags) {
        this.showmessage.tags.forEach((tag) => {
          post_tag.push(tag.id);
        });
        this.showmessage.tags = post_tag;
      }
      message
        .update(this.showmessage, messageid)
        .then((response) => {
          if (response != null) {
            this.$router.push({ name: "Front approval" });
          }
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.loader = false;
        });
    },
    approval_ng_message(messageid, msg) {
      this.btnStatus = msg
      $("#aprroval_ng_modal").show();
      $(".approval_submit").hide();
      $("#approval_input")[0].focus();
      this.approval_ng.id = messageid;
    },
    approval_ng_message_post() {
      $("#js-loader").show();
      message
        .show(this.approval_ng.id)
        .then((response) => {
          if (response != null) {
            this.showmessage = response.data.data;
            // this.showmessage.status = "却下";
            if(this.btnStatus === 'approve'){
              this.showmessage.status = "承認済";
              var post_tag = [];
              if (this.showmessage.tags) {
                this.showmessage.tags.forEach((tag) => {
                  post_tag.push(tag.id);
                });
                this.showmessage.tags = post_tag;
              }
            }else if(this.btnStatus === 'reject'){
              this.showmessage.status = "却下";
            }

            this.showmessage.published_at = this.format_date(
              this.editmessage.published_at
            );
            this.showmessage.is_approval = true;

            if (this.showmessage.tags) {
              delete this.showmessage.tags;
            }
            message
              .update(this.showmessage, this.approval_ng.id)
              .then((response) => {
                let toObj = response.data.data;
                if (response != null) {
                  let approvalContent = '';
                  if (this.approval_ng.content === undefined || this.approval_ng.content === '') {
                    approvalContent = 'no content'
                  }else{
                    approvalContent = this.approval_ng.content
                  }
                  var ng_form = {
                    title: this.btnStatus === 'reject' ? "却下メッセージ" : "承認済メッセージ",
                    content: approvalContent,
                    content_type: "Plane",
                    parent_id: this.approval_ng.id,
                    type: "approval_message",
                    status: "公開",
                    is_approval: true
                  };
                  message
                    .register(ng_form)
                    .then((response) => {
                      if (response != null) {
                        let fromObj = response.data.data;
                        let obj = {
                          to_user: toObj.from_id,
                          from_user: fromObj.from_id,
                          content: fromObj.content
                        }
                        message.approvalMail(obj,toObj.id).then((data)=>{
                          if (data !== null || data !== undefined) {
                            this.$router.push({ name: "Front approval" });
                          }
                        }).catch((error) => {
                          console.log('approval mail error',error);
                        })
                      }
                    })
                    .catch((error) => {
                      console.log(error);
                    })
                }
              })
              .catch((error) => {
                console.log(error);
              })
          }
        })
        .catch((error) => {
          console.log(error);
          this.$router.push({ name: "Front Error Catch" });
        })
    },
    async goodList(id) {
      this.loader = true;
      message
        .listChild(id, "board_good")
        .then(
          (response) => {
            if (response != null) {
              this.showmessage.good_num = response.data.data.length;
                response.data.data.forEach((ob, i) => {
                  if (ob.act_users[0].user_id == this.$User.id) {
                    this.showmessage.good_self = true;
                      if (this.showmessage.good_num !== 0) {
                        this.showmessage.good_self = true;
                      }
                  }
                });
            }
          },
          (error) => {
            console.log(error);
            this.$router.push({ name: "Front Error" });
          }
        )
        .catch((error) => {
          console.log(error);
          this.$router.push({ name: "Front Error Catch" });
        })
        .finally(() => {
          this.loader = false;
        });
    },
    async goodLikeList(id) {
      this.loader = true;
      message
        .listChild(id, "thumps_like")
        .then(
          (response) => {
            if (response != null) {
              this.showmessage.good_like_num = response.data.data.length;
                response.data.data.forEach((ob, i) => {
                  if (ob.act_users[0].user_id == this.$User.id) {
                    this.showmessage.good_like_self = true;
                      if (this.showmessage.good_like_num !== 0) {
                        this.showmessage.good_like_self = true;
                      }
                  }
                });

            }
          },
          (error) => {
            console.log(error);
            this.$router.push({ name: "Front Error" });
          }
        )
        .catch((error) => {
          console.log(error);
          this.$router.push({ name: "Front Error Catch" });
        })
        .finally(() => {
          this.loader = false;
        });
    },
    good_post(id) {
      
      this.good_message.parent_id = id;
      message
        .register(this.good_message)
        .then(
          (response) => {
            this.showmessage.good_num = this.showmessage.good_num + 1;
            this.showmessage.good_self = true;            
          })
    },
    good_like_post(id) {
      
      this.good_like_message.parent_id = id;
      message
        .register(this.good_like_message)
        .then(
          (response) => {

            this.showmessage.good_like_num = this.showmessage.good_like_num + 1;
            this.showmessage.good_like_self = true;          
          })
    },
    good_delete(id) {
        message
          .listChild(id, "board_good")
          .then(
            (response) => {
              if (response != null) {
                response.data.data.forEach((ob, i) => {
                  if (ob.act_users[0].user_id == this.$User.id) {
                    message
                      .delete(ob.id)
                      .then((response) => {
                        if (response != null) {
                          this.showmessage.good_num =
                            this.showmessage.good_num - 1;
                          this.showmessage.good_self = false;
                        }
                      })
                      .finally(() => {
                        this.loader = false;
                      });
                  }
                });
              }
            },
            (error) => {
              this.$router.push({ name: "Front Error" });
            }
          )
          .catch((error) => {
            this.$router.push({ name: "Front Error Catch" });
          })
          .finally(() => {
            this.loader = false;
          });

    },
    good_like_delete(id) {
        message
          .listChild(id, "thumps_like")
          .then(
            (response) => {
              if (response != null) {
                response.data.data.forEach((ob, i) => {
                  if (ob.act_users[0].user_id == this.$User.id) {
                    message
                      .delete(ob.id)
                      .then((response) => {
                        if (response != null) {
                          this.showmessage.good_like_num =
                          this.showmessage.good_like_num - 1;
                          this.showmessage.good_like_self = false;
                        }
                      })
                      .catch((error) => {
                         this.$router.push({ name: "Front Error Catch" });
                      })
                      .finally(() => {
                        this.loader = false;
                      });
                  }
                });
              }
            },
            (error) => {
              this.$router.push({ name: "Front Error" });
            }
          )
          .catch((error) => {
            this.$router.push({ name: "Front Error Catch" });
          })
          .finally(() => {
            this.loader = false;
          });
    },
    getComments(id) {
      message
      .listChild(id, "approval_message")
      .then((response) => {
          if (response != null) {
            if(response.data.data[0].content == 'no content') {
              this.approval_message = ''
            }
            else {
              this.approval_message = response.data.data[0].content
            }
          }
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.loader = false;
        });
    },
    ShowMessage(id) {
      message
        .show(id)
        .then((response) => {
          if (response != null) {
            this.goodList(id);
            this.goodLikeList(id);
            this.getComments(id);
            this.showmessage = response.data.data;
            this.showmessage.from_user = "";
            // <!--OTHER-4884 : 表示不整合箇所の修正対応  -->
            // this.posted_by = this.showmessage.created_by.last_name + ' ' + this.showmessage.created_by.first_name;
            this.posted_by = this.showmessage.deleted_created_by ? 
                              this.showmessage.created_by.last_name + ' ' + this.showmessage.created_by.first_name +' (削除済み) ':
                              this.showmessage.created_by.last_name + ' ' + this.showmessage.created_by.first_name ;
            if (response.data.approver_data != null && response.data.approver_data != undefined) {
              this.approverName = response.data.approver_data.user_name;
              // <!--OTHER-4884 : 表示不整合箇所の修正対応  -->
              // this.approved_by = response.data.approver_data.user_name;
              this.approved_by = response.data.approver_data.delete_at ? 
                                  response.data.approver_data.user_name + '(削除済み)' :
                                  response.data.approver_data.user_name ;
            } else {
              this.approved_by = '-- --';
            }

            if (
              this.showmessage.from_id != this.$User.id &&
              this.showmessage.target_ids.includes(this.$User.id) != true
            ) {
              this.$router.push({ name: "Front 404" });
            }

            if (this.message_type == "mysdgs") {
                let date = this.showmessage.published_at;
                let publishedDate = date.split('T')[0].split('-');
                // <!--OTHER-4884 : 表示不整合箇所の修正対応  -->
                // let createdBy = this.showmessage.created_by.last_name;
                let createdBy = this.showmessage.deleted_created_by ? this.showmessage.created_by.last_name + '(削除済み)': this.showmessage.created_by.last_name ;
                let sdgsReportTitle = `${publishedDate[0]}年${publishedDate[1]}月${publishedDate[2]}日 ${createdBy}さんの活動`;
                this.showmessage.sdgsReportTitle = sdgsReportTitle;
              }

            if (
              this.message_type == "sdgs" ||
              this.message_type == "challenge" || 
              this.message_type == "mysdgs"
            ) {
              if (this.IsJsonString(response.data.data.content)) {
                let contentSplit = JSON.parse(response.data.data.content);

                if (this.message_type == "mysdgs") {
                  this.showmessage.content = contentSplit.content;
                  
                }else{
                  this.showmessage.content = striptags(contentSplit.content);
  
                  this.showmessage.content_1 = striptags(contentSplit.content_1);
  
                  this.showmessage.content_2 = striptags(contentSplit.content_2);
  
                  this.showmessage.option = contentSplit.option;
                }

              } else {
                this.showmessage.content = striptags(
                  response.data.data.content
                );
                this.showmessage.option = striptags(response.data.data.content);
              }
            }

            //Jquery
            // youtubeがタグの構成上表示出来ないので以下でタグを変換している
            $(function () {
              $("figure.media").each(function () {
                var $y_url = $(this)
                  .children()
                  .attr("url")
                  .replace("https://youtu.be/", "");
                $y_url = $(this)
                  .children()
                  .attr("url")
                  .replace("https://www.youtube.com/watch?v=", "");
                $(this)
                  .children()
                  .replaceWith(
                    "<iframe class='post_youtube' title='YouTube video player' frameborder='0' allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture' allowfullscreen></iframe>"
                  );
                console.log($y_url);
                $(this)
                  .children(".post_youtube")
                  .attr("src", "https://www.youtube.com/embed/" + $y_url);
              });
            });
            this.getMessageMeta(id);
            this.getMessageEyecatch(id);
            this.getMessageAttachedFile(id);

            //below code is commented due to changing of names
            // if (this.showmessage.from_id != null) {
            // this.getFromUser(this.showmessage.from_id);
            // }

            // for aprovel user in column and photolog screen

            this.showmessage.from_user = "";

            if (this.message_type == "column" || this.message_type == "photolog") {
              //if message is approved, use approver name else use created name
              if (this.approverName != null && this.approverName != undefined) {
                this.actionUserLabel = "承認者";
                this.showmessage.from_user = this.approverName;
              }
              else {
                this.showmessage.from_user = this.showmessage.created_by.last_name + ' ' + this.showmessage.created_by.first_name;
              }
            } else //if not column and photolog use the created name only
            {
              this.showmessage.from_user = this.showmessage.created_by.last_name + ' ' + this.showmessage.created_by.first_name;
            }

            if (this.showmessage.target_ids != null) {
              this.getTargetUser(this.showmessage.target_ids);
            }
          }
        })
        .catch((error) => {
          console.log(error);
          // this.$router.push({ name: "Front Error Catch" });
        })
        .finally(() => { });
    },
    getFromUser(user_id) {
      user
        .show(user_id)
        .then((response) => {
          if (response != null) {
            this.showmessage.from_user = response.data.data;
          }
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => { });
    },
    getTargetUser(user_ids) {
      this.showmessage.target_user = [];
      user_ids.forEach((user_id) => {
        user
          .show(user_id)
          .then((response) => {
            if (response != null) {
              this.showmessage.target_user.push(response.data.data);
              if(this.message_type == 'daily'){
                // <!--OTHER-4884 : 表示不整合箇所の修正対応  -->
                // let target_user_name = this.showmessage.target_user[0].last_name + ' ' + this.showmessage.target_user[0].first_name
                let target_user_name = this.showmessage.target_user[0].deleted_at ?
                                      this.showmessage.target_user[0].last_name + ' ' + this.showmessage.target_user[0].first_name + ' (削除済み) ':
                                      this.showmessage.target_user[0].last_name + ' ' + this.showmessage.target_user[0].first_name;
                                      
                this.approved_by = target_user_name;
              }
            }
          })
          .catch((error) => {
            console.log(error);
          })
          .finally(() => {
            this.loader = false;
          });
      });
    },
    getMessageMeta(message_id) {
      if (this.messagecolumn["sdgs_type"]) {
        message
          .showmetakey(message_id, "key", "sdgs_type")
          .then((response) => {
            if (response != null) {
              this.showmessage.sdgs_type = response.data.data[0].value;
            }
          })
          .catch((error) => {
            console.log(error);
          })
          .finally(() => { });
      }
      if (this.messagecolumn["group"]) {
        message
          .showmetakey(message_id, "key", "group")
          .then((response) => {
            if (response != null) {
              this.showmessage.group = response.data.data[0].value;
            }
          })
          .catch((error) => {
            console.log(error);
          })
          .finally(() => { });
      }
      if (this.messagecolumn["public"]) {
        message
          .showmetakey(message_id, "key", "public")
          .then((response) => {
            if (response != null) {
              this.showmessage.public = response.data.data[0].value;
            }
          })
          .catch((error) => {
            console.log(error);
          })
          .finally(() => { });
      }
      if (this.messagecolumn["thanks_type"]) {
        message
          .showmetakey(message_id, "key", "thanks_type")
          .then((response) => {
            if (response != null) {
              this.showmessage.thanks_type = response.data.data;
            }
          })
          .catch((error) => {
            console.log(error);
          })
          .finally(() => { });
      }
    },

    getMessageEyecatch(message_id) {
      if (this.messagecolumn["photolog"]) {
        message
          .showfile(message_id, "key", "photolog")
          .then((response) => {
            if (response.data.data[0]) {
              this.showmessage.photolog = response.data.data[0];
              let fileApiPass =
                "/api/v1/file/" + this.showmessage.photolog.storage_file_name;
              Api()
                .get(fileApiPass, { responseType: "arraybuffer" })
                .then((response) => {
                  let blob = new Blob([response.data], {
                    type: response.headers["content-type"],
                  });
                  let img = document.getElementById("img_tag_id");
                  let url = window.URL || window.webkitURL;
                  img.src = url.createObjectURL(blob);
                });
            } else {
              this.getMessageEyecatchTime(message_id);
            }
          })
          .catch((error) => {
            console.log(error);
          })
          .finally(() => { });
      }
    },

    getMessageEyecatchTime(message_id) {
      var timer_id;
      var i = 0;

      timer_id = setInterval(
        function () {
          this.getMessageEyecatch(message_id);
          i++;
          if (i == 5) {
            clearInterval(timer_id);
          }
        }.bind(this),
        2000
      );
    },

    getMessageAttachedFile(message_id) {
      if (this.messagecolumn["attached_file"]) {
        message
          .showfile(message_id, "key", "attached_file")
          .then((response) => {
            if (response.data.data.length != 0) {
              this.showmessage.attached_file = response.data.data;
            }
          })
          .catch((error) => {
            console.log(error);
          })
          .finally(() => { });
      }
    },
    getFile(message_id) {
      message
        .showfile(message_id, "key", "attached_file")
        .then(
          (response) => {
            if (response != "") {
              this.files = response.data.data;
            }
          },
          (error) => {
            console.log(error);
            this.$router.push({ name: "Front Error" });
          }
        )
        .catch(() => {
          this.$router.push({ name: "Front Error Catch" });
        })
        .finally(() => { });
    },
    dlfile(path) {
      this.windowReference = window.open();
      let fileApiPass = "/api/v1/file/" + path;
      Api()
        .get(fileApiPass, { responseType: "arraybuffer" })
        .then((response) => {
          let blob = new Blob([response.data], {
            type: response.headers["content-type"],
          });
          /**
           * 新規タブ
           */
          this.windowReference.location = window.URL.createObjectURL(blob);
        });
    },
    replyList(id) {
      this.loader = true;
      message
        .listChild(id, "daily_res")
        .then(
          (response) => {
            if (response != null) {
              this.reply_messages = response.data.data;

              this.reply_messages.forEach((reply_message) => {
                user
                  .show(reply_message.from_id) //投稿者の取得
                  .then((response) => {
                    if (response != null) {
                      reply_message.from_user = response.data.data;
                    }
                  })
                  .catch((error) => {
                    console.log(error);
                  })
                  .finally(() => { });
              });
            }
          },
          (error) => {
            console.log(error);
            this.$router.push({ name: "Front Error" });
          }
        )
        .catch((error) => {
          console.log(error);
          this.$router.push({ name: "Front Error Catch" });
        })
        .finally(() => {
          this.loader = false;
        });
    },
    htmlText(msg) {
      return msg.replace(/\r?\n/g, "<br>");
    },
    showYear(year) {
      if (typeof year == "number") {
        year = year.toString();
      }

      let setYear = "";
      switch (year) {
        case "2022":
          setYear = "49期 (2022)";
          break;
        case "2023":
          setYear = "50期 (2023)";
          break;
        case "2024":
          setYear = "51期 (2024)";
          break;
        case "2025":
          setYear = "52期 (2025)";
          break;
        case "2026":
          setYear = "53期 (2026)";
          break;
        default:
          setYear = year;
          break;
      }

      return setYear;
    },
    approvelText(status, fromId) {
      return "承認申請";
    },
    seletecedUser(userId) {
      return this.approval_post_data.target_ids.includes(userId);
    },
    IsJsonString(str) {
      try {
        JSON.parse(str);
      } catch (e) {
        return false;
      }
      return true;
    },
  },
  updated() {
    console.log("アップデート");
  },
};
</script>

<style scoped>
/* 外部CSSスタイルシートを追加する */
@import "../../../../assets/front_component/scss/_sample_thanks_like_Button.scss";

.icon-like-wrap{
  border-radius: 50%;
  height: 31px;
  width: 31px;
  border: #3197e0 solid 1px;
  position: relative;
  cursor: pointer;
}
.word-break {
  word-break: break-all;
}

.icon-like-wrap:hover{
  background-color: #3197e0;
  cursor: pointer;
}
.icon-like-wrap:hover svg path{
  fill: #ffffff;
}

.icon-like{
  height: 22px; 
  width: 22px; 
  position: absolute;
  cursor: pointer;
  top: 3px;
  left: 4px;
}
.icon-like-path {
  stroke: none; 
  fill: #3197e0;
  transition: none;
}
</style>

